import React, { Component } from "react";
import ModalService from "./ModalService";
import { Modal as BootstrapModal, Button } from "react-bootstrap";

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      text: [],
      onOK: null,
      show: false,
      closeText: "Close",
      okText: "OK",
    };

    this.modalSubscription = null;
    this.handleClose = this.handleClose.bind(this);
    this.handleOK = this.handleOK.bind(this);
  }

  componentDidMount() {
    var self = this;
    this.modalSubscription = ModalService.showModal.subscribe((data) => {
      self.setState({
        title: data.title,
        text: Array.isArray(data.text) ? data.text : [data.text],
        onOK: data.onOK,
        closeText: data.closeText ? data.closeText : this.state.closeText,
        okText: data.okText ? data.okText : this.state.okText,
        show: true,
      });
    });
  }

  componentWillUnmount() {
    this.modalSubscription.unsubscribe();
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleOK() {
    if (this.state.onOK) this.state.onOK();

    this.handleClose();
  }

  render() {
    return (
      <BootstrapModal show={this.state.show} onHide={this.handleClose}>
        <BootstrapModal.Header>
          <BootstrapModal.Title>{this.state.title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {this.state.text.map((t) => t)}
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            {this.state.closeText}
          </Button>
          <Button variant="primary" onClick={this.handleOK}>
            {this.state.okText}
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  }
}
