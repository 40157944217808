import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

export default class NewClient extends React.Component {
  static displayName = "NewClient";

  render() {
    return (
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="eg. My Agency"
            name="cname"
            defaultValue={this.props.client.cname}
            onChange={this.props.handleChange}
            isInvalid={this.props.errors.cname?.length}
          />
          {this.props.errors.cname?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {this.props.errors.cname.map((e) => e)}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Label>Subdomain</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>https://</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="myagency"
            name="url"
            defaultValue={this.props.client.url}
            onChange={this.props.handleChange}
            isInvalid={this.props.errors.url?.length}
          />
          <InputGroup.Text>.staffconnect-app.com</InputGroup.Text>
          {this.props.errors.url?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {this.props.errors.url.map((e) => e)}
            </Form.Control.Feedback>
          )}
        </InputGroup>

        <Form.Group className="mb-3">
          <Form.Label>Main Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="eg. staff@myagency.com"
            name="email"
            defaultValue={this.props.client.email}
            onChange={this.props.handleChange}
            isInvalid={this.props.errors.email?.length}
          />
          {this.props.errors.email?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {this.props.errors.email.map((e) => e)}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control
            as="select"
            name="country_id"
            value={this.props.client.country_id}
            onChange={this.props.handleChange}
            isInvalid={this.props.errors.country_id?.length}
          >
            {this.props.countries.map((c) => {
              return (
                <option value={c.id} key={c.id}>
                  {c.display}
                </option>
              );
            })}
          </Form.Control>
          {this.props.errors.country_id?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {this.props.errors.country_id.map((e) => e)}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Timezone</Form.Label>
          <Form.Control
            as="select"
            name="timezone"
            value={this.props.client.timezone}
            onChange={this.props.handleChange}
            isInvalid={this.props.errors.timezone?.length}
          >
            {this.props.timezones.map((t) => {
              return (
                <option value={t.value} key={t.value}>
                  {t.key}
                </option>
              );
            })}
          </Form.Control>
          {this.props.errors.timezone?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {this.props.errors.timezone.map((e) => e)}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Button variant="primary" onClick={() => this.props.save()} disabled={this.props.disableCreate}>
          Create
        </Button>
      </Form>
    );
  }
}
