import React from "react";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import TabService from "./TabService";
//import Dashboard from "../dashboard/Dashboard";
import ClientsContainer from "../clients/list/ClientsContainer";

export default class Tabs extends React.Component {
  constructor() {
    super();
    this.state = {
      tabs: [],
      activeKey: null,
    };

    this.keyCounter = 0;

    this.closeTab = this.closeTab.bind(this);
  }

  componentDidMount() {
    //this.addTab(<Dashboard />, "Dashboard", false);
    this.addTab(<ClientsContainer />, "Clients");
    this.tabSubscription = TabService.openTab.subscribe(({ tab, title }) => {
      this.addTab(tab, title);
    });

    this.closeCurrentTabSubscription = TabService.closeCurrent.subscribe(() => {
      this.closeTab(this.state.activeKey);
    });
  }

  addTab(tab, title, canClose = true) {
    let tabs = this.state.tabs;
    let key = "tab_" + this.keyCounter++;
    let tabTitle = title || tab.type.displayName;

    let existing = null;
    if (
      tabs.some((t) => {
        if (t.title === tabTitle) existing = t.key;
        return t.title === tabTitle;
      })
    ) {
      this.setState({
        activeKey: existing,
      });
      return;
    }

    tabs.push({
      tab,
      title: tabTitle,
      key: key,
      canClose: canClose,
    });
    this.setState({
      tabs: tabs,
      activeKey: key,
    });
  }

  renderTab(tab) {
    let compWithProps = React.cloneElement(tab, {
      ...tab.props,
      tabActive: tab.eventKey === this.state.activeKey,
    });

    return compWithProps;
  }

  switchTab(e, key) {
    // it's called by closing a tab, skip
    if (e.target.classList.contains("close-tab")) return false;

    this.setState({
      activeKey: key,
    });
  }

  closeTab(key) {
    let tabs = this.state.tabs;
    let index = 0;
    tabs = tabs.filter((t, i) => {
      if (t.key !== key) return true;
      index = i;

      return false;
    });

    index--;

    if (!tabs[index]) index = 0;

    this.setState({
      tabs: tabs,
      activeKey: tabs.length ? tabs[index].key : null,
    });
  }

  render() {
    return (
      <Tab.Container activeKey={this.state.activeKey}>
        <Row>
          <Col>
            <Nav variant="tabs">
              {this.state.tabs.map((t) => {
                return (
                  <Nav.Item
                    onClick={(e) => this.switchTab(e, t.key)}
                    className="tab-title"
                    key={t.key}
                  >
                    <Nav.Link eventKey={t.key}>{t.title}</Nav.Link>
                    {t.canClose && (
                      <span
                        className="close-tab"
                        onClick={() => this.closeTab(t.key)}
                      >
                        +
                      </span>
                    )}
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Content>
              {this.state.tabs.map((t) => {
                return (
                  <Tab.Pane eventKey={t.key} key={t.key}>
                    {this.renderTab(t.tab)}
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}
