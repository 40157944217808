import { Subject } from "rxjs";

const ModalService = {
  showModal: new Subject(),

  show: function (title, text, onOK, closeText = null, okText = null) {
    this.showModal.next({ title, text, onOK, closeText, okText });
  },
};

export default ModalService;
