import React from "react";
import Clients from "./Clients.jsx";
import ClientService from "../ClientService.jsx";
import AlertService from "../../alert/AlertService.jsx";

export default class ClientsContainer extends React.Component {
  static displayName = "ClientsContainer";

  constructor(props) {
    super(props);

    this.state = {
      clients: [],
    };

    this.clientService = new ClientService();

    this.deleteClient = this.deleteClient.bind(this);
    this.addShortNote = this.addShortNote.bind(this);
    this.noteChange = this.noteChange.bind(this);
  }

  componentDidMount() {
    this.clientService.getAll().then((res) => {
      this.setState({ clients: res.data });
    });
  }

  deleteClient(id) {
    this.clientService
      .delete(id)
      .then((res) => {
        this.setState({
          clients: this.state.clients.filter((c) => c.id !== id),
        });
        AlertService.show("Client deleted.");
      })
      .catch((err) => {
        AlertService.show(err.response.data.message, "red");
      });
  }

  addShortNote(cIndex, what = true) {
    let clients = this.state.clients;

    clients[cIndex].add_short_note = what;

    this.setState({ clients: clients });
  }

  noteChange(c, e) {
    if (e.keyCode === 13) {
      this.clientService
        .addNote(c.id, { type: "short", note: e.target.value })
        .then((res) => {
          let clients = this.state.clients;
          this.setState({
            clients: clients.map((cl) => {
              if (cl.id === c.id) {
                cl.last_short_note = res.data.note;
                cl.add_short_note = false;
              }

              return cl;
            }),
          });
        })
        .catch((err) => {
          AlertService.show(err.response.data.message, "red");
        });
    }
  }

  render() {
    return (
      <Clients
        clients={this.state.clients}
        deleteClient={this.deleteClient}
        addShortNote={this.addShortNote}
        noteChange={this.noteChange}
      />
    );
  }
}
