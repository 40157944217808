import React, { Component } from "react";
import AlertService from "./AlertService.jsx";
import { Alert as BootstrapAlert } from "react-bootstrap";
import "./Alert.scss";

export default class Alert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "green",
      text: "",
      close: true,
      icon: false,
    };

    this.closeMessage = this.closeMessage.bind(this);
    this.subscribed = null;
    this.timeout = null;

    this.alertSubscription = null;
  }

  componentDidMount() {
    var self = this;
    this.alertSubscription = AlertService.showAlert.subscribe((data) => {
      clearTimeout(self.timeout);
      var timeout = 10000;

      if (data.noTimeout === true) timeout = 99999999;

      self.setState(
        {
          type: data.type,
          text: data.text,
          icon: data.icon,
          close: false,
        },
        function () {
          self.timeout = setTimeout(function () {
            self.setState({
              close: true,
            });
          }, timeout);
        }
      );
    });
  }

  componentWillUnmount() {
    this.alertSubscription.unsubscribe();
  }

  closeMessage() {
    this.setState({ close: true });
  }

  render() {
    const text =
      this.state.text.constructor === Array
        ? this.state.text
        : [this.state.text];
    const variant = this.state.type === "red" ? "danger" : "primary";
    return !this.state.close ? (
      <BootstrapAlert
        variant={variant}
        className="position-fixed start-50 translate-middle w-50"
      >
        {text.map(function (e, i) {
          return <span key={"alert_key_" + i}>{e}</span>;
        })}
      </BootstrapAlert>
    ) : null;
  }
}
