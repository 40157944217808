import React from "react";
import { Form, Button } from "react-bootstrap";

export default class EditPlan extends React.Component {
  static displayName = "EditPlan";

  render() {
    return (
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Plan</Form.Label>
          <Form.Control
            as="select"
            name="plan"
            value={this.props.plan}
            onChange={this.props.handleChange}
            isInvalid={this.props.errors.plan?.length}
          >
            {this.props.plans.map((p) => {
              return (
                <option value={p.id} key={p.id}>
                  {p.title}
                </option>
              );
            })}
          </Form.Control>
          {this.props.errors.plan?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {this.props.errors.plan.map((e) => e)}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        {this.props.plan !== 'free' &&
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Period</Form.Label>
              <Form.Control
                as="select"
                name="bill_period"
                value={this.props.bill_period}
                onChange={this.props.handleChange}
                isInvalid={this.props.errors.bill_period?.length}
              >
                <option value="monthly">
                  Monthly
                </option>
                <option value="yearly">
                  Yearly
                </option>
              </Form.Control>
              {this.props.errors.currencies?.length > 0 && (
                <Form.Control.Feedback type="invalid">
                  {this.props.errors.currencies.map((e) => e)}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                name="bill_enable"
                label="Billing Enabled"
                checked={this.props.bill_enable}
                onChange={this.props.handleChange}
                isInvalid={this.props.errors.bill_enable?.length}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Bill Start Date</Form.Label>
              <Form.Control
                type="date"
                name="bill_start"
                value={this.props.bill_start}
                onChange={this.props.handleChange}
                isInvalid={this.props.errors.bill_start?.length}
              />
              {this.props.errors.bill_start?.length > 0 && (
                <Form.Control.Feedback type="invalid">
                  {this.props.errors.bill_start.map((e) => e)}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                as="select"
                name="currency"
                value={this.props.currency}
                onChange={this.props.handleChange}
                isInvalid={this.props.errors.currency?.length}
              >
                {this.props.currencies.map((c, i) => {
                  return (
                    <option value={c} key={c + i}>
                      {c}
                    </option>
                  );
                })}
              </Form.Control>
              {this.props.errors.currencies?.length > 0 && (
                <Form.Control.Feedback type="invalid">
                  {this.props.errors.currencies.map((e) => e)}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {!this.props.oldPlan &&
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>Base Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="base_price"
                    value={this.props.base_price}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.base_price?.length}
                  />
                  {this.props.errors.base_price?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.base_price.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Included Admins</Form.Label>
                  <Form.Control
                    type="number"
                    name="free_admins"
                    step="1"
                    value={this.props.free_admins}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.free_admins?.length}
                  />
                  {this.props.errors.free_admins?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.free_admins.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Additional Admin Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="admin_price"
                    value={this.props.admin_price}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.admin_price?.length}
                  />
                  {this.props.errors.admin_price?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.admin_price.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Included Staff</Form.Label>
                  <Form.Control
                    type="number"
                    name="free_staff"
                    step="1"
                    value={this.props.free_staff}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.free_staff?.length}
                  />
                  {this.props.errors.free_staff?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.free_staff.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Staff Block Size</Form.Label>
                  <Form.Control
                    type="number"
                    name="staff_increments"
                    value={this.props.staff_increments}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.staff_increments?.length}
                  />
                  {this.props.errors.staff_increments?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.staff_increments.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Additional Staff Block Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="staff_price"
                    value={this.props.staff_price}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.staff_price?.length}
                  />
                  {this.props.errors.staff_price?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.staff_price.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>App Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="app_price"
                    value={this.props.app_price}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.app_price?.length}
                  />
                  {this.props.errors.app_price?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.app_price.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
            }

            {this.props.oldPlan &&
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="unit_price"
                    value={this.props.unit_price}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.unit_price?.length}
                  />
                  {this.props.errors.unit_price?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.unit_price.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Minimum Total</Form.Label>
                  <Form.Control
                    type="number"
                    name="min_total"
                    value={this.props.min_total}
                    onChange={this.props.handleChange}
                    isInvalid={this.props.errors.min_total?.length}
                  />
                  {this.props.errors.min_total?.length > 0 && (
                    <Form.Control.Feedback type="invalid">
                      {this.props.errors.min_total.map((e) => e)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
            }

            {this.props.stripe_id === null && (
              <Form.Group className="mb-3">
                <Form.Label>Account Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={this.props.email}
                  onChange={this.props.handleChange}
                  isInvalid={this.props.errors.email?.length}
                />
                {this.props.errors.email?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {this.props.errors.email.map((e) => e)}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </div>
        }

        <Button variant="primary" onClick={() => this.props.save()} disabled={this.props.disableSave}>
          Save
        </Button>
      </Form>
    );
  }
}
