import Config from "../../config/config";
import axios from "axios";

class ClientService {
  getAll() {
    return axios.get(Config.api + "clients");
  }

  getById(id) {
    return axios.get(Config.api + "client/" + id);
  }

  update(id, data) {
    return axios.put(Config.api + "client/" + id, data);
  }

  newClientFormData() {
    return axios.get(Config.api + "newClient");
  }

  create(data) {
    return axios.post(Config.api + "newClient", data);
  }

  getEditPlanData(customer_id) {
    return axios.get(Config.api + "plan/" + customer_id);
  }

  savePlan(customer_id, data) {
    return axios.put(Config.api + "plan/" + customer_id, data);
  }

  emailClients(data) {
    return axios.post(Config.api + "clients/email", data);
  }

  delete(id) {
    return axios.delete(Config.api + "clients/" + id);
  }

  addNote(id, data) {
    return axios.post(Config.api + "clients/" + id + "/note", data);
  }

  updateNote(client_id, note_id, data) {
    return axios.put(
      Config.api + "clients/" + client_id + "/note/" + note_id,
      data
    );
  }

  deleteNote(client_id, note_id) {
    return axios.delete(
      Config.api + "clients/" + client_id + "/note/" + note_id
    );
  }

  getNotes(id) {
    return axios.get(Config.api + "clients/" + id + "/notes");
  }
}

export default ClientService;
