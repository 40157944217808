import { Subject } from "rxjs";

const TabService = {
  openTab: new Subject(),
  closeCurrent: new Subject(),

  open(tab, title = null) {
    this.openTab.next({ tab, title });
  },

  closeCurrentTab() {
    this.closeCurrent.next();
  },
};

export default TabService;
