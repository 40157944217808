import React from "react";
import Users from "./Users.jsx";
import UserService from "./../UserService.jsx";

export default class UsersContainer extends React.Component {
  static displayName = "UsersContainer";

  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };

    this.userService = new UserService();
  }

  componentDidMount() {
    this.userService.getAll().then((res) => {
      this.setState({ users: res.data });
    });
  }

  render() {
    return <Users users={this.state.users} />;
  }
}
