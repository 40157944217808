import React from "react";
import Notes from "./Notes";
import ClientService from "../ClientService";
import AlertService from "../../alert/AlertService";

export default class NotesContainer extends React.Component {
  static displayName = "NotesContainer";

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      disableSave: false,
      notes: [],
      type: "info",
      note: "",
      edit_id: null,
    };

    this.clientService = new ClientService();

    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.clientService.getNotes(this.props.client_id).then((res) => {
      this.setState({ notes: res.data });
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  save() {
    let isUpdate = this.state.edit_id !== null;

    let data = {
      type: this.state.type,
      note: this.state.note,
    };

    this.setState({
      disableSave: true,
    });

    let request = isUpdate
      ? this.clientService.updateNote(
          this.props.client_id,
          this.state.edit_id,
          data
        )
      : this.clientService.addNote(this.props.client_id, data);

    request
      .then((res) => {
        let notes = this.state.notes;
        if (isUpdate) {
          notes = notes.map((n) => {
            if (n.id === res.data.note.id) n = res.data.note;

            return n;
          });
        } else {
          notes.unshift(res.data.note);
        }
        this.setState({
          notes: notes,
          note: "",
          type: "info",
          edit_id: null,
          disableSave: false,
        });
        AlertService.show(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.errors)
          this.setState({
            errors: err.response.data.errors,
          });

        if (err.response.data.message)
          AlertService.show(err.response.data.message, "red");

        this.setState({
          disableSave: false,
        });
      });
  }

  edit(note) {
    this.setState({
      edit_id: note.id,
      type: note.type,
      note: note.note,
    });
  }

  delete(note) {
    this.clientService
      .deleteNote(this.props.client_id, note.id)
      .then((res) => {
        let notes = this.state.notes;
        notes = notes.filter((n) => n.id !== note.id);
        this.setState({ notes: notes });
        AlertService.show(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message)
          AlertService.show(err.response.data.message, "red");
      });
  }

  render() {
    return (
      <Notes
        {...this.state}
        save={this.save}
        handleChange={this.handleChange}
        edit={this.edit}
        delete={this.delete}
      />
    );
  }
}
